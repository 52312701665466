/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';


const MetaTags: React.FC = () => {

  return (
    <HelmetProvider>
      <Helmet>
        <meta name="Description" content={`${process.env.REACT_APP_HELMET_DESCRIPTION}`} />
        <meta property="og:title" content={`${process.env.REACT_APP_HELMET_OG_TITLE}`} />
        <meta property="og:description" content={`${process.env.REACT_APP_HELMET_OG_DESCRIPTION}`} />
        <meta property="og:image" content={`/images/${process.env.REACT_APP_FOLDER_IMAGE}/icons/maskable_icon_x512.png`} />

        <meta name="msapplication-TileColor" content={`${process.env.REACT_APP_HELMET_COLOR}`} />
        <meta name="theme-color" content={`${process.env.REACT_APP_HELMET_COLOR}`} />

        <link rel="apple-touch-startup-image" media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)" href={`/images/${process.env.REACT_APP_FOLDER_IMAGE}/splash/1125x2436px.png`} />
        <link rel="apple-touch-startup-image" media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)" href={`/images/${process.env.REACT_APP_FOLDER_IMAGE}/splash/750x1334px.png`} />
        <link rel="apple-touch-startup-image" media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3)" href={`/images/${process.env.REACT_APP_FOLDER_IMAGE}/splash/1242x2208px.png`} />
        <link rel="apple-touch-startup-image" media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)" href={`/images/${process.env.REACT_APP_FOLDER_IMAGE}/splash/640x1136px.png`} />
        <link rel="apple-touch-startup-image" media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2)" href={`/images/${process.env.REACT_APP_FOLDER_IMAGE}/splash/1536x2048px.png`} />
        <link rel="apple-touch-startup-image" media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2)" href={`/images/${process.env.REACT_APP_FOLDER_IMAGE}/splash/1668x2224px.png`} />
        <link rel="apple-touch-startup-image" media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2)" href={`/images/${process.env.REACT_APP_FOLDER_IMAGE}/splash/2048x2732px.png`} />

        <link rel="icon" href={`/images/${process.env.REACT_APP_FOLDER_IMAGE}/icons/logo.svg`} color={`${process.env.REACT_APP_HELMET_COLOR}`} />
        <link rel="mask-icon" href={`/images/${process.env.REACT_APP_FOLDER_IMAGE}/icons/maskable_icon.png`} color={`${process.env.REACT_APP_HELMET_COLOR}`} />
        <link rel="shortcut-icon" href={`/images/${process.env.REACT_APP_FOLDER_IMAGE}/icons/maskable_icon.png`} color={`${process.env.REACT_APP_HELMET_COLOR}`} />
        <link rel="apple-touch-icon" type="image/png" href={`/images/${process.env.REACT_APP_FOLDER_IMAGE}/icons/maskable_icon.png`} color={`${process.env.REACT_APP_HELMET_COLOR}`} />
        <title>{`${process.env.REACT_APP_HELMET_TITLE}`}</title>
      </Helmet>
    </HelmetProvider>
  )
};

export default MetaTags;
