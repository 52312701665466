/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useRef, useEffect, useState } from 'react'

import { useField } from '@unform/core'
import { Container, Label, InputText, Span } from './styles'

interface Props {
  name: string
  label?: string
}
type InputProps = JSX.IntrinsicElements['input'] & Props
const Input: React.FC<InputProps> = ({ name, label, ...rest }: any) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { fieldName, defaultValue, registerField, error } = useField(name)
  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRef.current,
    })
  }, [fieldName, registerField])

  const [inputValue, setInputValue] = useState('')

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  function handleChange(s: string, type: string): void {
    if (type !== 'document') {
      setInputValue(s)
    } else {
      const masked = maskDocument(s)
      setInputValue(masked)
    }
  }

  function maskDocument(preValue: string): string {
    let value = preValue
    value = value.replace(/\D/g, '')
    if (value.length <= 11) {
      value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')

    } else {
      value = value.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        '$1.$2.$3/$4-$5'
      )
    }
    return value
  }

  return (
    <Container>
      {label && <Label>{label}</Label>}
      <InputText
        id={fieldName}
        className={error ? 'error' : ''}
        ref={inputRef}
        defaultValue={defaultValue}
        {...rest}
        value={inputValue}
        onChange={(e) => {
          handleChange(e.target.value, e.target.id);
          // console.log(e.target.id)
        }}
      />
      <Span>{error ? `*${error}` : ''}</Span>
    </Container>
  )
}
export default Input
