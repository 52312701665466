import styled from 'styled-components';

// Mobile Notification

export const ContainerMobile = styled.div`
  width: 100%;
  height: 70px;
  padding: 5px 10px;
  background-color: transparent;
  transition: 0.3s;
  display: none;
  @media only screen and (max-width: 768px){
    display: ${props => props.draggable ? 'flex' : 'none'};
  }
`;

export const Box = styled.div`
  width: 100%;
  min-height: 60px;
  border-radius: 8px;
  background-color: var(--secondaryColor);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 20px;
  padding-left: 20px;
`;

export const Close = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  width: 60px;
  min-height: 60px;
  align-items: center;
  justify-content: center;
  border-left: #002F5C solid 1px;
`;

export const Text = styled.span`
  color: #fff;
  font-size: 14px;
  font-family: 'Roboto';
  font-weight: 400;
`;

// Web Notification

export const WebContainer = styled.div`
  display: flex;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #000000aa;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 768px){
    display: none;
  }
`;

export const WebModal = styled.div`
  width: 600px;
  height: 300px;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
`;

export const HeaderModal = styled.div`
  width: 100%;
  height: 60px;
  background-color: var(--secondaryColor);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 20px;
  span{
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 24px;
    color: #fff;
  }
`;

export const BodyModal = styled.div`
  width: 600px;
  height: 240px;
  display: flex;
  flex-direction: row;
  padding: 40px;
  gap: 50px;
`;

export const BodyRightModal = styled.div`
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  span{
    color: #2B2B2B;
    font-size: 18px;
    font-family: 'Roboto';
    font-weight: 300;
    line-height: 25px;
  }
  button{
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-radius: 8px;
    background-color: var(--primaryColor);
    color: #fff;
  }
`;
