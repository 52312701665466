import React, {useRef} from 'react';
import { Switch, Route } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {gsap} from "gsap";

import PrivateRoute from "../components/PrivateRoute";
import GenericError from '../components/GenericError';

import AppRoutes from "./app.routes";
import Loading from '../pages/Loading';
import Login from "../pages/Login";

import './routes.css';

const AuthRoutes: React.FC = () => {

  const title = useRef(null);
  const info = useRef(null);
  const nodeRef = useRef(null);

  const onEnter = ():void => {
      gsap.from(
          [title.current, info.current],
          {
              duration: 0.6,
              y: 30,
              delay: 0.6,
              ease: 'power3.inOut',
              opacity: 0,
              stagger: {
                  amount: 0.3,
              },
          }
      );
  };

  const onExit = ():void => {
      gsap.to(
          [title.current,info.current],
          {
              duration: 0.6,
              y: -30,
              ease: 'power3.inOut',
              opacity: 0,
              stagger: {
                  amount: 0.15,
              },
          }
      );

  };

  return(
    <TransitionGroup>
      <CSSTransition
        timeout={600}
        nodeRef={nodeRef}
        onEnter={onEnter}
        onExit={onExit}
        unmountOnExit
      >
        <Switch>
          <Route path="/login" key="login" component={Login} />
          <Route path="/redirect" key="redirect" component={Loading} />
          <Route path="/error" key="error" component={GenericError} />
          <PrivateRoute path="/" key="app" component={AppRoutes} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  )
};

export default AuthRoutes;
