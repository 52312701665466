import React from 'react';

import { useHistory } from 'react-router-dom';
import { Container } from './styles';
import iconError from '../../assets/files/error.svg';

const GenericError: React.FC = () => {
  const history = useHistory();
  return (
    <Container>
      <div>
        <img src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/logo-horizontal.svg`} alt="Logo icone" />
      </div>
      <span>Oops, parece que ocorreu um erro!</span>
      <img src={iconError} alt="Icone de Erro" />
      <button type="button" onClick={()=> {history.push('/')}}>Ir para o início</button>
    </Container>
);
}

export default GenericError;
