import React from 'react';

import { ReactDiv } from './styles';

const Recaptcha = ({googleRecapt}:{googleRecapt: boolean}):any => {
  return (
    <ReactDiv>
      <div className="img">
        <img className='animated' unselectable={googleRecapt?"on":"off"} src="https://www.gstatic.com/recaptcha/api2/logo_48.png" alt="reCAPTCHA" />
      </div>
      <div className="text">
        <span>protegido por reCAPTCHA</span>
        <span>
          <a href="https://www.google.com/intl/pt-BR/policies/privacy/" rel="noreferrer" target="_blank">Privacidade</a>
          {' '}
          -
          {' '}
          <a href="https://www.google.com/intl/pt-BR/policies/terms/" rel="noreferrer" target="_blank">Termos</a>
        </span>
      </div>
    </ReactDiv>
  );
}

export default Recaptcha;
