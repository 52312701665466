/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import { BrowserRouter, Router, } from "react-router-dom";
import { createBrowserHistory } from 'history';
import { Provider } from "react-redux";
import ReactGA from 'react-ga';
import { loadReCaptcha } from "react-recaptcha-v3";
import AuthRoutes from './routes/auth.routes';
import store from "./store";
import GlobalStyle from "./styles/global";
import RouteChangeTracker from "./components/RouteChangeTracker";
import Dev from "./components/Dev";
import './styles/routes.css';
import InternetConnection from "./components/InternetConnection";
import MetaTags from "./MetaTags";
// eslint-disable-next-line import/no-unresolved
// const analyticsKey = process.env.REACT_APP_TRACKING_ID as string;
// const browserHistory = createBrowserHistory();
// browserHistory.listen((location, action) => {
//   ReactGA.pageview(location.pathname + location.search)
// });

const App: React.FC = () => {

  const siteKey = process.env.REACT_APP_RECAPTCHA as string;
  loadReCaptcha(siteKey);

  return (
    <Provider store={store}>
      {/* <Router history={browserHistory}> */}
      <BrowserRouter>
        <MetaTags />
        <Dev />
        <InternetConnection />
        <GlobalStyle />
        <AuthRoutes />
        <RouteChangeTracker />
      </BrowserRouter>
    </Provider>
  )
};

export default App;
