import styled from 'styled-components'

export const Container = styled.div`
  padding: 0;
  width: 100vw;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  #mobile {
    display: none;
  }
  #web {
    display: flex;
  }
  @media only screen and (max-width: 768px) {
    overflow: scroll;
    //background: #000;
    max-height: 100%;
    // position: relative;

    #mobile {
      display: flex;
    }
    #web {
      display: none;
    }
  }
`

export const Box = styled.div`
  width: 1250px;
  margin: 0 auto;
  display: flex;
  gap: 10px;
  max-height: 90%;
  min-height: 90%;

  overflow: hidden;
  flex-direction: row;
  justify-content: space-between;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width: 1024px) {
    width: 100vw;
  }
  @media only screen and (max-width: 768px) {
    width: 100vw;
    max-height: 97%;
    min-height: 0;
    padding: 10px;
  }
  @media only screen and (min-width: 769px) {
    max-height: 100%;
  }
`

export const BoxPrice = styled.div`
  border: none;
  box-shadow: 0px 0px 2px #555;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 874px;
  z-index: 3;
  transition: 0.3s;
  height: 60px;
  border-radius: 8px;
  align-items: center;
  background: #fff;
  position: absolute;
  color: var(--secondaryColor);
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  button {
    background-color: var(--primaryColor);
    width: 220px;
    height: 40px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 8px;
    color: #fff;
    font-weight: 500;
    transition: 0.2s;
    &:hover {
      background-color: var(--primaryColorLight);
    }
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    position: absolute;
    display: none;
  }
`

export const BoxInfoDownload = styled.div`
  z-index: 12;
  font-family: 'Roboto';
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 296px;
  height: 40px;
  border-radius: 8px;
  margin: auto;
  position: absolute;
  background: var(--secondaryColor);
  span {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
  }
  @media only screen and (max-width: 768px) {
    width: 95%;
    font-weight: 500;
    font-size: 18px;
    div {
      margin: 10px;
      width: 65vw;
    }
  }
`

export const BoxInfoCopy = styled.div`
  z-index: 12;
  font-family: 'Roboto';
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 296px;
  height: 40px;
  border-radius: 8px;
  position: absolute;
  background: var(--secondaryColor);
  span {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
  }
  @media only screen and (max-width: 768px) {
    width: 95%;
    width: 95%;
    font-weight: 500;
    font-size: 18px;
  }
`

export const BoxInfo = styled.div`
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Roboto';
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 296px;
  height: 145px;
  border-radius: 8px;
  transition: 0.3s;
  position: absolute;
  background-color: #555;
  @media only screen and (max-width: 768px) {
    width: 100vw;
    font-weight: 500;
    font-size: 15px;
    div {
      margin: 10px;
      width: 65vw;
    }
  }
`

export const Left = styled.div`
  width: 100%;
  max-height: 100%;
  //height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

export const BoxInvoicesPending = styled.div`
  margin-top: 15px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  max-height: 100%;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  .shake_item {
    border: 2px solid #092a6040;
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    @keyframes shake {
      10%,
      90% {
        transform: translate3d(-1px, 0, 0);
      }

      20%,
      80% {
        transform: translate3d(2px, 0, 0);
      }

      30%,
      50%,
      70% {
        transform: translate3d(-4px, 0, 0);
      }

      40%,
      60% {
        transform: translate3d(4px, 0, 0);
      }
    }
  }

  #INVOICE_CONTAINER {
    
  }

  .border_unselected {
    border: 2px solid transparent;

    :hover {
      border: 2px solid #092a6040;
    }
  }

  .border_selected {
    border: 2px solid #092A60;

    :hover {
      border: 2px solid #092A60;
    }
  }

  > div {
    > div {
      span {
        color: #494949;
        font-size: 16px;
        line-height: 28px;
        font-family: 'Roboto';
        font-weight: 500;
      }
    }
    span {
      color: #494949;
      font-size: 14px;
      line-height: 28px;
      font-family: 'Roboto';
      font-weight: 500;
    }
    button {
      // margin-left: 8px;
      border: none;
      // width: 35px;
      // height: 35px;
      // border-radius: 8px;
      // background: #edf1f5;
      display: flex;
      // align-items: center;
      // justify-content: center;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
  .btnConfirm {
    margin: 0px;
    width: 100%;
    height: 35px;
    background: var(--primaryColor);
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 500;
    color: #fff;
  }
  .btnProccess {
    margin: 0px;
    width: 100%;
    height: 35px;
    background: #fff;
    border: 1.5px solid var(--secondaryColor);
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 500;
    color: var(--secondaryColor);
    cursor: initial;
  }
  .btnOpen {
    border: 1px solid var(--secondaryColor);
    margin: 0px;
    width: 100%;
    height: 35px;
    background: #fff;
    font-family: 'Roboto';
    cursor: default;
    font-size: 14px;
    font-weight: 500;
    color: var(--secondaryColor);
  }
  .nameClient {
    color: #858585;
    font-size: 12px;
    font-family: 'Roboto';
    line-height: 18px;
    font-weight: 300;
  }
  .valueAndDate {
    color: #858585;
    font-size: 15px;
    font-family: 'Roboto';
    line-height: 25px;
    font-weight: 500;
  }

  @media only screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

export const BoxInvoiceRight = styled.div`
  background-color: #fff;
  position: relative;
  width: 296px;
  z-index: 1;
  padding: 18px 18px 0 18px;
  height: 180px;

  #pagamento_aprovado_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 11px;
    margin-top: 10px;

    border-top: solid 1px #f0f0f0;
  }

  #pagamento_aprovado_texto {
    text-align: left;
    font: normal normal normal 11px/13px Roboto;
    letter-spacing: 0px;
    color: #494949;
    opacity: 1;
    width: 158px;
    margin-left: 11px;
  }

  .invoice_fatura_analise {
    background: #f0f0f0 0% 0% no-repeat padding-box;
    border: 1px solid #00000000;
    border-radius: 18px;
    flex-direction: row;
    align-items: center;
    padding: 9px;
    justify-content: center;
    height: 36px;
    border: 1px solid #00000000;

    font: normal normal 500 13px/28px Roboto;
    letter-spacing: 0px;
    color: #9f9f9f;

    width: 100%;

    .mr-6 {
      margin-right: 6px;
    }

    .InfoGrayIcon {
      display: block;
    }
    .InfoBlackIcon {
      display: none;
    }

    :hover {
      .InfoGrayIcon {
        display: none;
      }
      .InfoBlackIcon {
        display: block;
      }
    }
  }
`

export const Right = styled.div`
  max-width: 314px;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    max-width: 100%;
    width: 100%;
  }

  @media only screen and (min-width: 769px) {
    max-height: 90%;
  }
`
export const BoxPriceMobile = styled.button`
  border: none;
  height: 60px;
  left: 0px;
  bottom: 0px;
  transition: 0.5s ease;
  padding: 20px;
  margin: 0px;
  box-shadow: 0px 0px 10px #ccc;
  display: none;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  z-index: 20;
  transition: 0.3s;
  align-items: center;
  background: var(--primaryColor);
  position: fixed;
  color: #fff;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 15px;
  @media only screen and (max-width: 768px) {
    display: flex;
  }
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: 0px;
  // justify-content: space-between;
  flex-direction: row;
  // justify-content: center;
  .title {
    padding: 5px 0px;
    position: relative;
    display: flex;
    // width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 6px;
    border: none;
    // font-size: 28px;
    // font-family: 'Roboto';
    // font-weight: 600;
    // color: #064076;
    flex: 1;

    font: normal normal bold 20px/28px Roboto;
    letter-spacing: 0px;
    color: #092a60;
    opacity: 1;
  }
  .numberInvTotal {
    height: 24px;
    padding: 5px 10px;
    // border-radius: 12px;
    //background-color: #064076;
    //color: #fff;
    //font-size: 13px;
    //font-weight: 500;
    display: flex;

    background: #092a60 0% 0% no-repeat padding-box;
    border-radius: 13px;

    font: normal normal 500 13px Roboto;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: uppercase;
  }
  .allInvoiceMobile {
    display: none;
  }
  @media only screen and (max-width: 768px) {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-left: 16px;
    .title {
      display: none;
    }
    .numberInvTotal {
      display: none;
    }
    .allInvoiceMobile {
      display: flex;
    }
  }
`

export const BoxInvoicePaymentContainer = styled.div`
  position: sticky;
  bottom: 0;

  // width: calc(100% - 40px);
  border: 0;

  width: calc(100%);
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  background-color: #ffffff;

  .JMCcontainer {
    p {
      margin: 0;
      font: normal normal normal 13px/28px Roboto;
      letter-spacing: 0px;
      color: #2b2b2b;
      text-transform: uppercase;
    }
    .JMCBold {
      text-align: right;
      font: normal normal bold 13px/28px Roboto;
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;
    padding-bottom: 8px;
    border-top: 1px solid #f0f0f0;
  }
`

export const BoxInvoicePaymentButton = styled.button`
  border-radius: 20px;
  height: 40px;
  background: #1bb92d;
  display: flex;
  justify-content: center;
  align-items: center;

  //position: sticky;
  //bottom: 0;

  // width: calc(100% - 40px);
  border: 0;

  width: calc(100%);
  //margin-left: 20px;
  //margin-right: 20px;

  p {
    text-align: center;
    font: normal normal 500 15px/28px Roboto;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
`
interface BoxAllInvoiceProps {
  empty?: boolean
  buttonShown?: boolean
}
export const BoxAllInvoices = styled.div<BoxAllInvoiceProps>`
  margin-top: 15px;
  width: 314px;
  border-radius: 16px;
  max-height: 100%;
  height: ${(props) => (props.empty ? 0 : '100%')};

  .shake_item {
    border: 2px solid #092a6040;
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    @keyframes shake {
      10%,
      90% {
        transform: translate3d(-1px, 0, 0);
      }

      20%,
      80% {
        transform: translate3d(2px, 0, 0);
      }

      30%,
      50%,
      70% {
        transform: translate3d(-4px, 0, 0);
      }

      40%,
      60% {
        transform: translate3d(4px, 0, 0);
      }
    }
  }
  //overflow: auto;
  overflow-y: scroll;
  overflow-y: overlay;
  -webkit-overflow-scrolling: auto; /* pare de rolar imediatamente */
  /* -webkit-box-shadow: inset 0px -30px 26px -33px rgba(0,0,0,0.6);
  -moz-box-shadow: inset 0px -30px 26px -33px rgba(0,0,0,0.6);
  box-shadow: inset 0px -30px 26px -33px rgba(0,0,0,0.6); */
  /*-ms-overflow-style: none;
  scrollbar-width: none;*/
  background-color: #fff;
  /* &::-webkit-scrollbar {
    width: 8px;
    height: 0px;
    background-color: var(--secondaryColor);
    position: absolute !important;
    right: 10px !important;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 20px;
    transform: translate(20px) !important;
  }
  &::-webkit-scrollbar-track {
    background-color: white;
    margin-top: 50px;
    border-bottom-right-radius: 10px;
    position: absolute !important;
    right: 10px !important;
    transform: translate(20px) !important;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--secondaryColor);
    border-radius: 20px;
  }*/

  #ScrollBContainer {
    padding: 20px;
    min-height: calc(100% - 124px);
    padding-bottom: 0;
    padding-top: 0;
  }

  #ScrollContainer {
    position: sticky;
    position: -webkit-sticky;
    display: block;
    // background: #F00;
    //-webkit-overflow-scrolling: auto; /* pare de rolar imediatamente */
  }
  ::-webkit-scrollbar {
    background: transparent;

    // display:none;
    width: 12px;
  }

  scrollbar-color: rgba(103, 103, 103, 0.5) transparent;
  scrollbar-width: none;

  &::-webkit-scrollbar-track {
    /*  margin-top:0;
      border-bottom-right-radius: 16px;
      background-image: url("https://i.stack.imgur.com/Pwbuz.png");
    background-repeat: repeat-y;
    background-size: contain;
      */
    margin-top: 54px;
    margin-bottom: ${(props) => (props.buttonShown ? '105' : '0')}px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(103, 103, 103, 0.5);
    border-radius: 20px;
    border: 2.5px solid rgba(0, 0, 0, 0);
    border-right: 5px solid rgba(0, 0, 0, 0);

    background-clip: padding-box;
    max-height: 5px;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    #ScrollContainer {
      position: sticky;
      position: -webkit-sticky;
      display: block;
      -webkit-overflow-scrolling: auto;
    }

    border-radius: 16px;

    ::-webkit-scrollbar {
      background: transparent;

      // display:none;
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      /*  margin-top:0;
      border-bottom-right-radius: 16px;
      background-image: url("https://i.stack.imgur.com/Pwbuz.png");
    background-repeat: repeat-y;
    background-size: contain;
      */
      margin-top: 54px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(103, 103, 103, 0.5);
      border-radius: 20px;
      border: 2.5px solid rgba(0, 0, 0, 0);
      //border-right: 5px solid rgba(0, 0, 0, 0);

      background-clip: padding-box;
      max-height: 5px;
    }

    /* pare de rolar imediatamente */
    /*
    &::-webkit-scrollbar {
      width: 8px;
      height: 0px;
      background-color: white;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 0px;
    }
    &::-webkit-scrollbar-track {
      background-color: white;
      margin-top: 0px;
      border-bottom-right-radius: 16px;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--secondaryColor);
      border-radius: 0px;
    }*/
  }

  .SelectedInvoicesHeader {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-bottom: 8px;
    border-bottom: 1px solid #f0f0f0;

    position: sticky;
    width: 100%;

    top: 0px;
    // z-index: 10;
    background: #ffffff;
    padding-top: 20px;

    //z-index:200000000;

    @media only screen and (max-width: 768px) {
      width: 100%;
      position: -webkit-sticky;
      position: -moz-sticky;
      position: -o-sticky;
      position: -ms-sticky;
      position: sticky;
      z-index: 1;
      display: block;
    }
  }
`

export const YearTitle = styled.h1`
  height: 50px;
  border-radius: unset;

  width: 314px;
  padding: 10px 15px;
  font-size: 20px;
  font-weight: 600;
  font-family: 'Roboto';
  line-height: 28px;
  // margin-bottom: 20px;
  background: var(--secondaryColor);
  color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
  // border-top-right-radius: 16px;
  // border-top-left-radius: 16px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    position: sticky;
    z-index: 1;
    display: block;
  }
`
interface InvoiceRight {
  open?: boolean
}
export const InvoiceV2 = styled.div<InvoiceRight>`
  border-radius: 16px;
  border: solid 1px #e0e0e0;
  background: #ffffff;
  margin-bottom: 20px;
  padding: 20px;

  #pagamento_aprovado_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 11px;
    margin-top: 10px;

    border-top: solid 1px #f0f0f0;
  }
  #pagamento_aprovado_texto {
    text-align: left;
    font: normal normal normal 11px/13px Roboto;
    letter-spacing: 0px;
    color: #494949;
    opacity: 1;
    width: 158px;
    margin-left: 11px;
  }

  .right_expand_img {
    transition: all 0.4s;
    transform: ${(props) => (props.open ? 'rotateX(180deg)' : 'rotateX(0deg)')};

    //transform: skew(180deg);
  }
  .invoiceItemFooter {
    border-top: 1px solid #f0f0f0;
    // background: #000;
  }

  .invoice_fatura_analise {
    background: #f0f0f0 0% 0% no-repeat padding-box;
    display: flex;
    border: 1px solid #00000000;
    border-radius: 18px;
    flex-direction: row;
    align-items: center;
    padding: 0;
    justify-content: center;
    height: 36px;
    border: 1px solid #00000000;

    font: normal normal 500 13px/28px Roboto;
    letter-spacing: 0px;
    color: #9f9f9f;

    width: 100%;

    .mr-6 {
      margin-right: 6px;
    }

    .InfoGrayIcon {
      display: block;
    }
    .InfoBlackIcon {
      display: none;
    }

    :hover {
      .InfoGrayIcon {
        display: none;
      }
      .InfoBlackIcon {
        display: block;
      }
    }
  }
`

export const Invoice = styled.div`
  width: 100%;
  margin-bottom: 10px;
  padding: 0px 0px 0px 0px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 5px;
  justify-content: space-between;
  span {
    line-height: 20px;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

export const BtnShowDetails = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 90px;
  height: 40px;
  background-color: #fff;
  border: none;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.55px;
  font-family: 'Roboto';
  font-weight: 500;
  color: var(--secondaryColor);
  border: 0.5px solid var(--secondaryColor);
`

export const BtnCopyDetails = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 100%;
  background-color: #edf1f5;
  height: 40px;
  border: none;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.55px;
  font-family: 'Roboto';
  font-weight: 500;
  color: var(--secondaryColor);
`

export const BtnHideDetails = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 100%;
  color: #edf1f5;
  height: 40px;
  border: none;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.55px;
  font-family: 'Roboto';
  font-weight: 500;
  background-color: var(--secondaryColor);
`

export const BtnPayDetails = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 100%;
  color: #edf1f5;
  height: 40px;
  border: none;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.55px;
  font-family: 'Roboto';
  font-weight: 500;
  background-color: var(--primaryColor);
`

export const Skeleton = styled.div`
  background-image: linear-gradient(
    -90deg,
    #fafafc 0%,
    #e4e4e4 50%,
    #fafafc 100%
  );
  background-size: 400% 400%;
  animation: shimmer 1.2s ease-in-out infinite;
  @keyframes shimmer {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`

export const SkeletonGreen = styled.div`
  background-image: linear-gradient(
    -90deg,
    #7fa323 0%,
    #abdb30 50%,
    #7fa323 100%
  );
  background-size: 400% 400%;
  animation: shimmer 1.2s ease-in-out infinite;
  @keyframes shimmer {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`

export const InvoiceRightExpanded = styled.div`
  padding-top: 5px;
  border-top: 1px solid #f0f0f0;
  overflow: hidden;
  //
`

export const InvoiceRightExpandedText = styled.p`
  text-align: left;
  font: normal normal normal 12px/15px Roboto;
  letter-spacing: 0px;
  color: #707070;
  //text-transform: uppercase;
  opacity: 1;
`

export const InvoiceInfoTitle = styled.p`
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #707070;
  text-transform: uppercase;
  opacity: 1;
`

export const InvoiceInfoText = styled.p`
  text-align: left;
  font: normal normal bold 16px/21px Roboto;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;

  display: flex;
  flex-direction: row;
  align-items: center;

  text-transform: capitalize;
`

interface InvoiceButtonProps {
  active?: boolean
  barCodeCopy?: boolean
}

export const InvoiceButton = styled.button<InvoiceButtonProps>`
  background: ${(props) =>
      props.active || props.barCodeCopy ? '#092A60' : '#edf1f5'}
    0% 0% no-repeat padding-box;
  border: 1px solid #00000000;
  border-radius: 18px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px;
  justify-content: flex-start;
  min-width: 36px;
  height: 36px;

  .invoice_check_img_mobile {
    opacity: ${(props) => (props.active ? 1 : 0)};
  }

  .invoice_selecionada {
    display: ${(props) => (props.active ? 'block' : 'none')};
  }

  .invoice_nao_selecionada {
    display: ${(props) => (props.active ? 'none' : 'block')};
  }

  .invoice_selecionada_mobile {
    display: ${(props) => (props.active ? 'block' : 'none')};
  }

  .invoice_nao_selecionada_mobile {
    display: ${(props) => (props.active ? 'none' : 'block')};
  }

  .baixarComprovante {
    margin: 0;
    text-align: left;
    font: normal normal 500 13px/28px Roboto;
    letter-spacing: 0px;
    color: ${(props) => (props.active ? '#FFFFFF' : '#092a60')};
    opacity: 1;
    :hover {
      color: #092a60;
    }
  }

  :hover {
    .invoice_selecionada {
      display: ${(props) => (props.active ? 'block' : 'none')};
    }
    .invoice_nao_selecionada {
      display: ${(props) => (!props.active ? 'block' : 'none')};
    }

    @media only screen and (min-width: 768px) {
      background: ${(props) =>
          // eslint-disable-next-line no-nested-ternary
          props.active
            ? '#092A60'
            : props.active !== undefined
            ? '#DEE3EA'
            : '#edf1f5'}
        0% 0% no-repeat padding-box;
    }
  }

  span {
    width: 18px;
    height: 18px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #092a60;
    border-radius: 9px;
    margin-right: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    .invoice_check_img {
      opacity: ${(props) => (props.active ? 1 : 0)};
    }
  }
  p {
    margin: 0;
    text-align: left;
    font: normal normal 500 12px/28px Roboto;
    letter-spacing: 0px;
    color: ${(props) => (props.active ? '#FFFFFF' : '#092a60')};
    opacity: 1;
  }

  transition: all 0.4s;
`
