/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { StateInitial } from '../../utils/models';

import { Container, Modal } from './styles';

interface Data{
  modules: boolean;
  dispatch: any;
}

const ModalDocEmpty = ({ modules, dispatch }:Data):any => {

  const [delay, setDelay] = useState(false);

  function setShowModal(module: boolean, showModal: boolean): unknown{
    localStorage.removeItem('first_time');
    return {
      type: 'SET_VISIBILITY_MODAL',
      module,
      showModal,
    };
  }

  useEffect(() => {
    setTimeout(() => {
      setDelay(modules);
    }, 200);
  },[modules]);

  return (
    <Container
      style={{
        transition: '0.3s',
        display: delay ? 'flex' : modules ? 'flex' : 'none',
        opacity: modules ? delay ? 1 : 0 : 0
      }}
    >
      <Modal>
        <img src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/iconModalEmptyDoc.svg`} alt="Woman" />
        <div>
          <h2>Opa!!</h2>
          <p>
            Verificamos que seu cadastro está incompleto.
            Procure uma loja de atendimento
            para regularizar sua situação.
          </p>
          <button
            onClick={() => {
              dispatch(setShowModal(modules, !modules))
            }}
            type="button"
          >
            Continuar
          </button>
        </div>
      </Modal>
    </Container>
);
}
export default connect((state: StateInitial) => ({modules: state.SHOW_MODAL}))(ModalDocEmpty);
