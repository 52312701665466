/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux';
import {
  Container,
  Label,
  Box,
  LabelConsumer,
  Skeleton
} from './styles';
import electrician from "../../assets/files/electrician.svg"
import { LoaderIcon } from '../../components/Loader';
import {User} from '../../utils/models';

const PickUC: React.FC = ({modules, dispatch}: any) => {

  const [user, setUser] = useState<Array<User>>([]);
  const [loading, setLoading] = useState(true);
  const temp = localStorage.getItem('infoClient');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(()=>{
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  },[]);

  useEffect(()=>{
    localStorage.removeItem('invoices');
  },[])

  useEffect(()=>{
    if(!temp){
      setUser([])
      setLoading(false)
    }else{
      const userTemp = JSON.parse(temp);
      setUser(userTemp)
      setLoading(false)
    }
  },[temp])

  const history = useHistory();

  function setClient(module: any, data: User): unknown {
    return {
        type: 'SET_UC',
        module,
        data,
    };
  }

  function setNameClient(module: any, name: string): unknown {
    return {
        type: 'SET_NAME',
        module,
        name,
    };
  }

  function handleUC(item: User): void{
    dispatch(setClient(modules, item));
    dispatch(setNameClient(modules, item.NOME_CLIENTE || 'SEM NOME'));
    localStorage.setItem(
      'info',
      JSON.stringify(item),
    );
    history.push("/");
  }

  if(loading){
    return <LoaderIcon />
  }

  return(
    <Container>
      <Label>
        Selecione a UC que deseja consultar
      </Label>
      {isLoading ?
        user?.map(() => {
          return (
            <Box disabled>

              <div style={{display:'flex',alignItems:'center'}}>
                <img src={electrician} alt="Energia" style={{height:25,width:25,marginRight:20}} />
                <LabelConsumer>
                  <span>UNIDADE CONSUMIDORA</span>
                  <Skeleton style={{opacity: 0.75, width: 120, height: 25, marginTop: 3,borderRadius:13}} />
                </LabelConsumer>
              </div>
              <LabelConsumer style={{marginLeft:20, width: 100}}>
                <span>ENDEREÇO DA UC</span>
                <Skeleton style={{opacity: 0.75, width: 100, height:10, marginTop: 3,borderRadius:5}} />
                <Skeleton style={{opacity: 0.75, width: 70, height:10, marginTop: 5,borderRadius:5}} />
              </LabelConsumer>
              
              <FontAwesomeIcon style={{width:25,height:25}} icon={faArrowRight} size="2x" />

              
            </Box>
          )
        }) :
        user?.map((item: User) => {
          return(
            <Box onClick={()=>{
              handleUC(item);
              dispatch(setClient(modules, item));
            }}
            >
              <div style={{display:'flex',alignItems:'center'}}>
                <img src={electrician} alt="Energia" style={{height:25,width:25,marginRight:20}} />
                <LabelConsumer>
                  <span>UNIDADE CONSUMIDORA</span>
                  <text>{item.IDENT_UC}</text>
                </LabelConsumer>
              </div>
              <LabelConsumer style={{marginLeft:20, width: 200,marginRight:15}}>
                <span style={{marginBottom:4}}>ENDEREÇO DA UC</span>
                <label>{`${item.LOGR_TIPO_NOME } - ${item.NOME_BAIRRO}`}</label>
              </LabelConsumer>
              
              <FontAwesomeIcon style={{width:25,height:25}} icon={faArrowRight} size="2x" />
            </Box>
          )
        })}
    </Container>
  )
};

export default connect((state: any) => ({modules: state}))(PickUC);
