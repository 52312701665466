import { isAfter } from 'date-fns'

function sortByProperty(property:string) {
	return function (a:any, b:any) {
		if (a[property] > b[property]) return 1;
		if (a[property] < b[property]) return -1;

		return 0;
	};
}

function sortJsonArray(JSONARRAY:any, property:string, order:boolean):any[] {
	JSONARRAY.sort(sortByProperty(property));

	if (order) return JSONARRAY;
	return JSONARRAY.reverse();
}


function sortByPropertyDate(property:string) {
	return function (a:any, b:any) {
        const am = new Date(a[property])
        const bm = new Date(b[property]);

		if (isAfter(am,bm)) return 1;
		if (isAfter(bm,am)) return -1;

		return 0;
	};
}

export function sortByDate(JSONARRAY:any, property:string, order:boolean):any[] {
	JSONARRAY.sort(sortByPropertyDate(property));

	if (order) return JSONARRAY;
	return JSONARRAY.reverse();
}

export default sortJsonArray;
