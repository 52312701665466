import styled from 'styled-components';

export const Container = styled.div`
  background-color:#F2F9FD;
`;

export const Span = styled.span`
  font-size: 10px;
  color: var(--secondaryColor);
`;

export const SpanInfo = styled.span`
  font-size: 13px;
`;

export const TableInvoices = styled.div`
  border: 1px solid #000;
  font-family: 'Roboto', 'Bold';
  display: flex;
  flex-direction: column !important;
  justify-content: flex-start;
  width: 100%;
  border-radius: 9px;
  padding: 0px;
  margin-bottom: 5px;
  div:first-child{
    border-top: none;
  }
  >div{
    border-top: 2.5px solid #2B2B2B;
    padding: 5px;
    width: 100%;
    >div{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      >div{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        span:first-child{
          font-size: 12px;
          font-weight: 600;
        }
        span{
          font-size: 6px;
        }
      }
    }
  }
`;

export const Line = styled.div`
  margin-top: 7px;
  margin-bottom: 7px;
  display: flex;
  width: 100%;
  height: 0.5px;
  background-color: var(--primaryColor);
`;

export const BlackLine = styled.div`
  margin-top: 7px;
  margin-bottom: 7px;
  display: flex;
  width: 100%;
  height: 1px;
  background-color: #000;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  overflow: hidden;
`;

export const Left = styled.div`
  margin-top: 3px;
  display: flex;
  flex-direction: column;
  line-height: 1.6;
`
export const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
`;

export const Title = styled.span`
  font-size: 8px;
  font-weight: bold;
  color: #2B2B2B;
`;


export const Bold = styled.span`
  font-size: 8px;
  font-family: 'Roboto', 'Bold';
  font-weight: bold;
  color: #2B2B2B;
`;

export const Online = styled.span`
  font-size: 6px;
  color: #2B2B2B;
`;

export const Cliente = styled.span`
  font-size: 8px;
  font-family: 'Roboto', 'Bold';
  font-weight: bold;
  color: #2B2B2B;
  word-spacing: 2px;
`;

export const Field = styled.span`
  font-size: 6px;
  color: #2B2B2B;
  text-align: right;
  padding-right: 2px;
  width: 100%;
  line-height: 1.6;
`;

export const Data = styled.span`
  font-size: 6px;
  color: #2B2B2B;
`;
