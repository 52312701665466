import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
  body {
    text-rendering: optimize-speed;
    background: #F0F0F0 ;
    -webkit-font-smoothing: antialiased;
    -ms-overflow-style: none;
    scrollbar-width: none;
    .grecaptcha-badge {
      visibility: hidden;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    @media only screen and (max-width: 768px){
      overflow: hidden;
    }
  }

  body, input, button {
    font: 16px "Roboto", "Nunito", sans-serif;
  }
  button {
    cursor: pointer;
  }
  :root{
    --primaryColor25:  ${process.env.REACT_APP_PRIMARY_25};
    --primaryColor:  ${process.env.REACT_APP_PRIMARY_COLOR};
    --primaryColorLight: ${process.env.REACT_APP_PRIMARY_COLOR_LIGHT};
    --faturaVencida: ${process.env.REACT_APP_VENCIDA};
    --primaryColorDark: ${process.env.REACT_APP_PRIMARY_COLOR_DARK};
    --secondaryColor: ${process.env.REACT_APP_SECONDARY_COLOR};
    --backgroundColor: ${process.env.REACT_APP_BACKGROUND_COLOR};
    --backgroundColorLight:${process.env.REACT_APP_BACKGROUND_COLOR_LIGHT};
    --faturaPaga:#28A745
  }
`;
