/* eslint-disable @typescript-eslint/explicit-function-return-type */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
import { formatDistanceToNowStrict } from 'date-fns';
import cookie from 'react-cookies'

const DECIMAL_NUMBER = 10;
const THIRTY_MINUTES = 30 * 60000;

export const isAuthenticated = ():boolean => {
  const encript = sessionStorage.getItem('token_created');

  const getCookie = cookie.load('OrangeLabs');

  if(getCookie && !encript){
    const token = atob(getCookie).slice(0,15);
    if(token === process.env.REACT_APP_TOKEN){
      const UC = parseInt(atob(getCookie).slice(15,23),10);
      localStorage.setItem('_UC_SITE', UC.toString());
      const date = atob(getCookie).slice(23,31);
      const dateResp = new Date(
        parseInt(date.slice(4,8), 10),
        parseInt(date.slice(2,4), 10)-1,
        parseInt(date.slice(0,2), 10)
      );
      const today = new Date();
      if(dateResp.toISOString().slice(0,10) === today.toISOString().slice(0,10)){
        return false;
      }
    }
  }

  if(encript){
    const [decript,] = atob(encript).split(":s");
    const testDateDistance = formatDistanceToNowStrict(new Date(decript), { unit: 'second' });
    const [time,] = testDateDistance.split(" ");
    if(parseInt(time, DECIMAL_NUMBER) > THIRTY_MINUTES){
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('token_created');
      return false;
    }
    return true;
  }
  return false;
};
