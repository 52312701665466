import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cookie from 'react-cookies'
import { StateInitial, User } from '../../utils/models'
import {
  Container,
  Box,
  Label,
  Content,
  Logout,
  Menu,
  BarLoading,
} from './styles'

interface Data {
  modules: StateInitial
  dispatch: any
}

const Header = ({ modules, dispatch }: Data): any => {
  const history = useHistory()
  const [nameClient, setNameClient] = useState<string | null>()
  const [ucClient, setUcClient] = useState<string | null>()
  const [adress, setAdress] = useState<string | null>()
  const [showDetailsMobile, setShowDetailsMobile] = useState(false)
  const [loading, setLoading] = useState(false)

  const [limited, setLimited] = useState(true)

  useEffect(() => {
    // setLimited(modules.LIMITED)

    const infoPermit = sessionStorage.getItem('infoPermission') === 'true'

    setLimited(!infoPermit)
  }, [])
  useEffect(() => {
    setLoading(modules.SEARCH_DB)
    const userTemp = localStorage.getItem('info')
    const usersTemp = localStorage.getItem('infoClient')
    if (userTemp) {
      const userInfo = JSON.parse(userTemp) as User
      setUcClient(userInfo.IDENT_UC)
      setAdress(`${userInfo.LOGR_TIPO_NOME + userInfo.NOME_BAIRRO}`)
      setNameClient(userInfo.NOME_CLIENTE)
    } else if (!userTemp && usersTemp) {
      const userInfo = JSON.parse(usersTemp) as Array<User>
      setUcClient(userInfo[0].IDENT_UC)
      setAdress(`${userInfo[0].LOGR_TIPO_NOME + userInfo[0].NOME_BAIRRO}`)
      setNameClient(userInfo[0].NOME_CLIENTE || userInfo[0].LOGR_TIPO_NOME)
    }
  }, [modules])

  function reset(): unknown {
    return {
      type: 'RESET',
    }
  }

  function handleLogout(): void {
    localStorage.clear()
    sessionStorage.clear()
    cookie.remove('OrangeLabs')
    dispatch(reset())
    history.push('/login')
  }

  return (
    <Container>
      <Box
        draggable={loading}
        style={{
          height: showDetailsMobile ? '330px' : '90px',
          transition: '0.3s',
        }}
      >
        <div
          id="textTop"
          style={{
            borderBottom: showDetailsMobile ? '0.5px solid #9e9e9e' : 'none',
          }}
        >
          <div className="logoGrid">
            <img
              src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/logo-horizontal.svg`}
              alt={`${process.env.REACT_APP_NAME_ALT_IMAGE}`}
              className="logoBig"
            />
            <img
              style={{ height: 39 }}
              src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/logo.svg`}
              alt={`${process.env.REACT_APP_NAME_ALT_IMAGE}`}
              className="logoSmall"
            />
          </div>
          {limited ? (
            <div id="textViewMobile" style={{ gap: 20,marginBottom:10 }}>
              {ucClient && (
              <div
                id="uc"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <Content style={{ overflow: 'hidden',fontSize:12,textTransform:'uppercase',color:'#494949',fontFamily:'Roboto' }}>
                  UNIDADE CONSUMIDORA
                </Content>
                <Content>{`UC ${ucClient || '00000'}`}</Content>
              </div>
            )}
              <Logout onClick={handleLogout}>
                <img
                  style={{width:17, height:17}}
                  src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/logout.svg`}
                  alt="Sair"
                />
              </Logout>
            </div>
          ) : (
            <div id="textViewMobile" style={{ gap: 20 }}>
              {ucClient && (
                <div
                  id="uc"
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Content style={{ overflow: 'hidden' }}>
                    {`${nameClient ? `${nameClient.substr(0, 15)}...` : '-'}`}
                  </Content>
                  <Content>{`UC ${ucClient || '00000'}`}</Content>
                </div>
              )}
              <Menu
                onClick={() => {
                  setShowDetailsMobile(!showDetailsMobile)
                }}
              >
                <FontAwesomeIcon
                  style={{
                    transition: '0.3s',
                    transform: showDetailsMobile
                      ? 'rotate(180deg)'
                      : 'rotate(0deg)',
                  }}
                  icon={faChevronDown}
                  color="var(--secondaryColor)"
                  size="1x"
                />
              </Menu>
            </div>
          )}
          <div
            id="textViewPc"
            style={{ flexDirection: 'row', gap: 30, alignItems: 'center' }}
          >
            {ucClient && (
              <div id="uc" style={{ display: 'flex', flexDirection: 'column' }}>
                <Label>UNIDADE CONSUMIDORA</Label>
                <Content>{ucClient || '00000'}</Content>
              </div>
            )}
            {!limited && nameClient && (
              <div
                id="client"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <Label>CLIENTE ATUAL</Label>
                <Content>{nameClient || 'SEM NOME'}</Content>
              </div>
            )}
            {!limited && adress && (
              <div
                id="adress"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <Label>ENDEREÇO</Label>
                <Content>{adress}</Content>
              </div>
            )}
            <Logout onClick={handleLogout}>
              <img
                src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/logout.svg`}
                alt="Sair"
              />
            </Logout>
            <Menu
              onClick={() => {
                setShowDetailsMobile(!showDetailsMobile)
              }}
            >
              <FontAwesomeIcon
                style={{
                  transition: '0.3s',
                  transform: showDetailsMobile
                    ? 'rotate(180deg)'
                    : 'rotate(0deg)',
                }}
                icon={faChevronDown}
                color="var(--secondaryColor)"
                size="1x"
              />
            </Menu>
          </div>
        </div>
        <BarLoading
          style={{
            height: 2,
            visibility: modules.SEARCH_DB ? 'visible' : 'hidden',
          }}
        />
        <div id="textBody">
          {ucClient && (
            <>
              <Label>UNIDADE CONSUMIDORA</Label>
              <Content>{ucClient || '00000'}</Content>
            </>
          )}
          {nameClient && (
            <>
              <Label>CLIENTE ATUAL</Label>
              <Content>{nameClient || 'SEM NOME'}</Content>
            </>
          )}
          {adress && (
            <>
              <Label>ENDEREÇO</Label>
              <Content>{adress}</Content>
            </>
          )}
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Logout onClick={handleLogout}>
              <img
                src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/logout.svg`}
                alt="Sair"
                style={{ height: 20 }}
              />
            </Logout>
            <h3 style={{ color: '#494949' }}>Sair</h3>
          </div>
        </div>
      </Box>
    </Container>
  )
}

export default connect((state: StateInitial) => ({ modules: state }))(Header)
