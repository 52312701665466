import React, { useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'
import { nanoid } from 'nanoid'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import cookie from 'react-cookies'
import { ReCaptcha } from 'react-recaptcha-v3'
import { User } from '../../utils/models'
import { Container } from './styles'
import { api } from '../../services/api'

const Loading: React.FC = ({ modules, dispatch }: any) => {
  const getCookie = cookie.load('OrangeLabs')
  const [googleRecaptcha, setGoogleRecaptcha] = useState<string | undefined>(
    undefined
  )
  const [captcha, setCaptcha] = useState<ReCaptcha>()

  const history = useHistory()

  useEffect(() => {
    async function search(): Promise<void> {
     //  console.log('cookie',atob(getCookie))
      const baseEncript = btoa(`${nanoid()}:${atob(getCookie)}`)
      sessionStorage.setItem(
        'token_created',
        btoa(`${new Date().toISOString()}:s${nanoid()}`)
      )
      const uc = localStorage.getItem('_UC_SITE')
      sessionStorage.setItem('token', baseEncript)
      if (googleRecaptcha) {
        try {
          const { data } = await api.get<any, AxiosResponse<User>>(
            `/ref/${uc}/current`,
            {
              headers: {
                Authorization: `Basic ${baseEncript}`,
                'X-Recaptcha-Token': googleRecaptcha,
                'X-Forwarded-Host':
                  window.location.hostname || process.env.REACT_APP_HOST,
              },
            }
          )
          dispatch(setClient(modules, data))
          dispatch(setNameClient(modules, data.NOME_CLIENTE || 'SEM NOME'))
          localStorage.setItem('infoClient', JSON.stringify(data))
          localStorage.setItem('info', JSON.stringify(data))
          sessionStorage.setItem('infoPermission','true')

          if (!data) {
            localStorage.clear()
            sessionStorage.clear()
            dispatch({
              type: 'RESET',
            })
            history.push('/login')
          } else {
            localStorage.setItem('first_time', 'true')
            history.push('/')
          }
        } catch (error) {
          localStorage.clear()
          sessionStorage.clear()
          dispatch({
            type: 'RESET',
          })
          history.push('/login')
        }
      }
    }
    search()
  }, [googleRecaptcha])

  function setClient(module: any, data: User): unknown {
    return {
      type: 'SET_UC',
      module,
      data,
    }
  }

  function setNameClient(module: any, name: string): unknown {
    return {
      type: 'SET_NAME',
      module,
      name,
    }
  }

  const verifyCallback = (recaptchaToken: string): void => {
    setGoogleRecaptcha(recaptchaToken)
  }

  return (
    <Container>
      <ReCaptcha
        ref={(e) => {
          if (e) setCaptcha(e)
        }}
        sitekey={process.env.REACT_APP_RECAPTCHA as string}
        action="auth_device"
        verifyCallback={verifyCallback}
      />
      <img
        src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/loading-primary.svg`}
        alt="Carregando"
        style={{ height: 140, margin: 0 }}
      />
      <span>Carregando</span>
    </Container>
  )
}

export default connect((state: any) => ({ modules: state.NOME_CLIENTE }))(
  Loading
)
