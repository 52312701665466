import React from 'react';
import { Rotate, Rotate2 } from './styles';

export const LoaderIcon: React.FC = () => {
  return (
    <Rotate2>
      <img src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/spinner.svg`} alt="Loader" />
    </Rotate2>
  );
};

export const Loader: React.FC = () => {
  return (
    <Rotate>
      <img src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/spinner.svg`} alt="Loader" />
      <h2>Carregando</h2>
    </Rotate>
  );
};
export const Loading = React.createElement(Loader);
