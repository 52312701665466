import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  .spanWeb{
    margin-top: 10px;
    margin-left: 20px;
    font-size: 13px;
    font-weight: 500;
    border-radius: 16px;
    padding: 5px 10px;
    height: 25px;
    background-color: var(--secondaryColor);
    color:#fff;
  }
`;

export const Menu = styled.button`
  position: relative;
  padding: 5px 14px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 6px;
  border: none;
  font-size: 28px;
  font-family: 'Roboto';
  font-weight: 600;
`;

export const ListSelect = styled.div`
  overflow: hidden;
  width: 320px;
  border-radius: 8px;
  background-color: #fff;
  padding: 8px;
  position: absolute;
  margin-top: 50px;
  z-index: 10;
  box-shadow: 0px 12px 24px #0000005C;
`;

export const OptionSelect = styled.button`
  background-color: #fff;
  width: 100%;
  height: 58px;
  border-radius: 6px;
  display: flex;
  padding: 6px 16px;
  flex-direction: column;
  justify-content: center;
  border: solid 1px #fff;
  transition: 0.2s;
  color: #676767;
  cursor: pointer;
  &:hover{
    border: solid 1px var(--secondaryColor);
    color: var(--secondaryColor);
  }
`;

export const OptionSelectTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
`;

export const OptionSelectDescription = styled.div`
  font-weight: 400;
  font-size: 11px;
`;
