/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { faCheck, faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Menu, Modal, HeaderModal, ListSelect, OptionSelect, OptionSelectDescription, OptionSelectTitle, ApplyButton } from './styles';

interface DataDTO{
  myInv: number;
  otherInv: number;
  allInv: number;
  handleFilter: any;
}

const SelectFilterMobile = ({allInv=0, myInv=0, otherInv=0, handleFilter}:DataDTO):any => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('Meus débitos');
  const [actualInvoicesPending, setActualInvoicesPending] = useState(0);
  const [myInvoicesPending, setMyInvoicesPending] = useState(0);
  const [otherInvoicesPending, setOtherInvoicesPending] = useState(0);
  const [allInvoicesPending, setAllInvoicesPending] = useState(0);
  const [actualSelectable, setActualSelectable] = useState<'1'|'0'|null>();

  useEffect(()=>{
    if(otherInv === 0){
      setActualInvoicesPending(myInvoicesPending)
    }
  },[allInv, myInv, myInvoicesPending, otherInv])

  useEffect(()=>{
    setMyInvoicesPending(myInv);
    setOtherInvoicesPending(otherInv);
    setAllInvoicesPending(allInv);
  },[allInv, myInv, otherInv, actualInvoicesPending])

  function handleApply():void{
    if(actualSelectable === '1'){
      setTitle('Meus débitos');
      setActualInvoicesPending(myInvoicesPending)
    }else if(actualSelectable === '0'){
      setTitle('Clientes anteriores');
      setActualInvoicesPending(otherInvoicesPending)
    }else{
      setTitle('Todos os débitos');
      setActualInvoicesPending(allInvoicesPending)
    }
    handleFilter(actualSelectable);
    setIsOpen(false);
  }

  return(
    <Container>
      <Menu
        disabled={otherInv === 0}
        onClick={()=>{
        setIsOpen(!isOpen);
      }}
        style={{
          transition: '0.3s',
          color: 'var(--secondaryColor)',
        }}
      >
        <div style={{textAlign: 'start', display: 'flex', flexDirection: 'column' }}>
          <span className="spanTitle">
            {title}
          </span>
          <span className="spanDescription">
            {actualInvoicesPending > 1 ?
            `${actualInvoicesPending} FATURAS PENDENTES`
            : actualInvoicesPending === 1
            ? `${actualInvoicesPending} FATURA PENDENTE`
            : 'SEM FATURAS PENDENTES'}
          </span>
        </div>
        {
          otherInv === 0 ? null :<FontAwesomeIcon icon={faChevronDown} style={{marginLeft: 10, marginTop: 3, transition: '0.2s',}} size='sm'  />
        }
      </Menu>
      <Modal style={{bottom: isOpen ? '0px' : '-400px'}}>
        <HeaderModal>
          <FontAwesomeIcon color="#fff" icon={faTimes} style={{marginLeft: 10, marginTop: 3, transition: '0.2s', height: 20, width: 20}}   />
          Filtrar débitos
          <button onClick={()=>{setIsOpen(!isOpen)}} type="button">
            <FontAwesomeIcon icon={faTimes} style={{marginLeft: 10, marginTop: 3, transition: '0.2s', height: 20, width: 20}}   />
          </button>
        </HeaderModal>
        <ListSelect>
          <OptionSelect onClick={()=>{
            setActualSelectable('1');
          }}
          >
            <div>
              <OptionSelectTitle>Meus débitos</OptionSelectTitle>
              <OptionSelectDescription>
                {myInv > 1 ?
                `${myInv} FATURAS PENDENTES`
                : myInv === 1
                ? `${myInv} FATURA PENDENTE`
                : 'SEM FATURAS PENDENTES'}
              </OptionSelectDescription>
            </div>
            <div style={{
              transition: '0.2s',
              border:'2px solid var(--secondaryColor)',
              height: 24,
              width: 24,
              borderRadius: '50%',
              background: actualSelectable === '1' ? 'var(--secondaryColor)':'#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
              }}
            >
              <FontAwesomeIcon icon={faCheck} color="#fff" style={{height: 12}} />
            </div>
          </OptionSelect>
          <OptionSelect onClick={()=>{
            setActualSelectable('0');
          }}
          >
            <div>
              <OptionSelectTitle>Clientes anteriores</OptionSelectTitle>
              <OptionSelectDescription>
                {otherInv > 1 ?
                `${otherInv} FATURAS PENDENTES`
                : otherInv === 1
                ? `${otherInv} FATURA PENDENTE`
                : 'SEM FATURAS PENDENTES'}
              </OptionSelectDescription>
            </div>
            <div style={{
              transition: '0.2s',
              border:'2px solid var(--secondaryColor)',
              height: 24,
              width: 24,
              borderRadius: '50%',
              background: actualSelectable === '0' ? 'var(--secondaryColor)':'#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
              }}
            >
              <FontAwesomeIcon icon={faCheck} color="#fff" style={{height: 12}} />
            </div>
          </OptionSelect>
          <OptionSelect onClick={()=>{
              setActualSelectable(null);
            }}
          >
            <div>
              <OptionSelectTitle>Todos os débitos</OptionSelectTitle>
              <OptionSelectDescription>
                {otherInv+myInv > 1 ?
                `${otherInv+myInv} FATURAS PENDENTES`
                : otherInv+myInv === 1
                ? `${otherInv+myInv} FATURA PENDENTE`
                : 'SEM FATURAS PENDENTES'}
              </OptionSelectDescription>
            </div>
            <div style={{
              transition: '0.2s',
              border:'2px solid var(--secondaryColor)',
              height: 24,
              width: 24,
              borderRadius: '50%',
              background: actualSelectable === null ? 'var(--secondaryColor)':'#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
              }}
            >
              <FontAwesomeIcon icon={faCheck} color="#fff" style={{height: 12}} />
            </div>
          </OptionSelect>
        </ListSelect>
        <ApplyButton onClick={handleApply}>
          APLICAR
        </ApplyButton>
      </Modal>
    </Container>
  )
}

export default SelectFilterMobile;
